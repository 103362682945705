/**
 * Polyfill to support NodeList.forEach on internet explorer and
 * other browsers that do not support it
 * Source: https://developer.mozilla.org/en-US/docs/Web/API/NodeList/forEach
 */
if (window.NodeList && !NodeList.prototype.forEach) {
    NodeList.prototype.forEach = function (callback, thisArg) {
        thisArg = thisArg || window;
        for (var i = 0; i < this.length; i++) {
            callback.call(thisArg, this[i], i, this);
        }
    };
}

import { flatten as _flatten } from 'lodash-es';

if (!Array.prototype.flat) {
	Array.prototype.flat = function() { 
		return _flatten(this); 
	}
}
