import { logException } from '@/logic/services/events/errorLogging';
import { generateGuestUserId } from '@/logic/helpers/utils';
import { resetIsBrazeInitializedForSession } from '@/logic/services/crm/braze';

if (!window.ferryhopper) {
  logException('Missing global configuration', {});
}
window.ferryhopper = window.ferryhopper || {};

window.ferryhopper.api = window.ferryhopper.api || 'https://microapi.ferryhopper.com/';
window.ferryhopper.crs = window.ferryhopper.crs || 'https://crs.ferryhopper.com';
window.ferryhopper.cdn = window.ferryhopper.cdn || 'https://images.ferryhopper.com/';
window.ferryhopper.fh_page_lang = window.ferryhopper.fh_page_lang || 'en';
window.ferryhopper.is_mobile = window.ferryhopper.is_mobile || false;
window.ferryhopper.min_wait_time = window.ferryhopper.min_wait_time || 45;
window.ferryhopper.max_wait_time = window.ferryhopper.max_wait_time || 72 * 60;
window.ferryhopper.min_conjunction_wait_time = window.ferryhopper.min_conjunction_wait_time || 5;
window.ferryhopper.close_trip_warning = window.ferryhopper.close_trip_warning || 15;
window.ferryhopper.default_search_date_distance = window.ferryhopper.default_search_date_distance || 16;

// Default value is false, and changes on each entry point
window.ferryhopper.cobranded_page = window.ferryhopper.cobranded_page || false;

window.ferryhopper.affiliate = window.ferryhopper.affiliate || 'ferryhopper';

resetIsBrazeInitializedForSession();
generateGuestUserId();

import './polyfills';
